/**
 *  Copyright (C) 2022 - Jeci SARL - https://jeci.fr
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU Affero General Public License as
 *  published by the Free Software Foundation, either version 3 of the
 *  License, or (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU Affero General Public License for more details.
 *
 *  You should have received a copy of the GNU Affero General Public License
 *  along with this program.  If not, see https://www.gnu.org/licenses/agpl-3.0.html.
 */

import { createRouter, createWebHistory } from "vue-router";
import {
  useUserStore,
  useConfigStore,
  alfrescoNodeService,
  workspaceService,
} from "@pristy/pristy-libvue";
import { START_LOCATION } from "vue-router";
import App from "./App.vue";

const routes = [
  {
    path: "/",
    name: "app",
    component: App,
    children: [
      {
        path: "",
        name: "welcome",
        meta: {
          requiresAuth: true,
        },
        component: () => import("./components/HelloWorld.vue"),
      },
      {
        path: "/manage-users",
        name: "manage-users",
        meta: {
          requiresAuth: true,
        },
        component: () => import("./pages/ManageUsersPage.vue"),
      },
      {
        path: "/corbeille",
        name: "corbeille",
        meta: {
          requiresAuth: true,
        },
        component: () => import("./pages/CorbeillePage.vue"),
      },
      {
        path: "/profile",
        name: "profile",
        meta: {
          requiresAuth: true,
        },
        component: () => import("./pages/ProfilPage.vue"),
      },
      {
        path: "/requests",
        name: "requests",
        meta: {
          requiresAuth: true,
        },
        component: () => import("./pages/RequestsPage.vue"),
      },
      {
        path: "/:pathMatch(.*)*",
        name: "not-found-page",
        meta: {
          requiresAuth: true,
        },
        component: () => import("./pages/NotFoundPages.vue"),
      },
    ],
  },
  {
    path: "/preview/:code",
    name: "preview",
    meta: {
      requiresAuth: false,
    },
    component: () => import("./pages/PreviewPage.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("./pages/LoginPage.vue"),
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    meta: {
      requiresAuth: false,
    },
    component: () => import("./pages/ForgotPasswordPage.vue"),
  },
  {
    path: "/reset-password",
    name: "reset-password",
    meta: {
      requiresAuth: false,
    },
    component: () => import("./pages/ResetPasswordPage.vue"),
  },
  {
    path: "/error/:id",
    name: "error",
    component: () => import("./pages/ErrorPage.vue"),
  },
];

const router = createRouter({
  history: createWebHistory("/portail"),
  routes,
});

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

async function connectKeycloak() {
  const user = useUserStore();
  // We wait for Keycloak init, then we can call all methods safely
  while (user.keycloak == null || user.keycloak.createLoginUrl === null) {
    await sleep(100);
  }
  if (user.keycloak.authenticated) {
    while (!user.isLoggedIn) {
      await sleep(100);
      await user.validateSession();
    }
    if (user.person === null) {
      await user.getPerson();
    }
  } else {
    const loginUrl = user.keycloak.createLoginUrl();
    window.location.replace(loginUrl);
  }
}

router.beforeEach(function () {
  window.scrollTo(0, 0);
});

import {
  loadLocaleMessages,
  setI18nLanguage,
  i18n,
} from "@/services/TranslationService";

// navigation guards
router.beforeEach(async (to, from) => {
  if (from === START_LOCATION) {
    // Load locale only on first page laoding
    let locale =
      to.params.locale ||
      localStorage?.getItem("user.language") ||
      window?.navigator?.language ||
      "fr";

    // load locale messages
    if (!i18n.global.availableLocales.includes(locale)) {
      await loadLocaleMessages(i18n, locale);
    }

    // set i18n language
    setI18nLanguage(i18n, locale);
  }
});

router.beforeEach(async (to, from) => {
  const config = useConfigStore();
  const user = useUserStore();

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (config.AUTH === "keycloak" || config.AUTH === "lemonldap") {
      await connectKeycloak();
    } else {
      // Get Person to check if we are authenticate
      await user.getPerson();
      if (!user.isLoggedIn) {
        return { name: "login", query: { target: to.path } };
      }
    }
  }

  if (user.isLoggedIn) {
    if (!from.name && to.name === "login" && user.isLoggedIn) {
      return { name: "welcome" };
    }
  }
});

router.beforeEach((to, from, next) => {
  const espacePattern = /^\/([a-f\d-]+)$/;
  const sitePattern = /^\/site\/(.*)$/;

  const matchSite = to.fullPath.match(sitePattern);
  const matchEspace = to.fullPath.match(espacePattern);

  let config = useConfigStore();

  if (matchSite && matchSite.length > 1) {
    const nodeId = matchSite[1];
    workspaceService.getWorkspace(nodeId).then((site) => {
      alfrescoNodeService.getNode(site.guid).then((siteNode) => {
        let siteType = siteNode.properties["pm:typeEspace"];
        switch (siteType) {
          case "actes":
            window.location = `${config.ACTES_HOST}/${siteType}/site/${nodeId}`;
            break;
          case "marchés":
            window.location = `${config.MARCHES_HOST}/${siteType}/site/${nodeId}`;
            break;
          case null:
          default:
            window.location = `${config.ESPACES_HOST}/espaces/site/${nodeId}`;
            break;
        }
      });
    });
  } else if (matchEspace && matchEspace.length > 1) {
    const nodeId = matchEspace[1];
    alfrescoNodeService.getNode(nodeId).then((node) => {
      if (node.path.elements.some((element) => element.name === "Sites")) {
        let siteName = node.path.elements[2].name;
        workspaceService.getWorkspace(siteName).then((site) => {
          alfrescoNodeService.getNode(site.guid).then((siteNode) => {
            let siteType = siteNode.properties["pm:typeEspace"];
            switch (siteType) {
              case "actes":
                window.location = `${config.ACTES_HOST}/${siteType}/${nodeId}`;
                break;
              case "marchés":
                window.location = `${config.MARCHES_HOST}/${siteType}/${nodeId}`;
                break;
              case null:
              default:
                window.location = `${config.ESPACES_HOST}/espaces/${nodeId}`;
                break;
            }
          });
        });
      } else {
        window.location = `${config.ESPACES_HOST}/espaces/${nodeId}`;
      }
    });
  } else {
    next();
  }
});

export default router;
